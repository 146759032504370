<template>
  <span>
    <CBadge
      v-if="data.deleted_at !== null"
      class="m-1 role_pill"
      shape="pill"
      color="danger"
      >Deleted</CBadge
    >
    <CBadge
      v-if="data.closed_at !== null"
      class="m-1 role_pill"
      shape="pill"
      color="dark"
      >Closed</CBadge
    >
    <CBadge
      v-if="data.dormant_at !== null"
      class="m-1 role_pill"
      shape="pill"
      color="info"
      >Dormant</CBadge
    >
    <CBadge
      v-if="data.sendtoclient_at !== null"
      class="m-1 role_pill"
      shape="pill"
      color="warning"
      >Send To Client</CBadge
    >
    <CBadge
      v-if="
        data.deleted_at === null &&
          data.dormant_at === null &&
          data.sendtoclient_at === null &&
          data.closed_at === null
      "
      class="m-1 role_pill"
      shape="pill"
      color="success"
      >Active</CBadge
    >
  </span>
</template>

<script>
export default {
  name: "FolderBadge",
  methods: {},
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
